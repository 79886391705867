import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ImageService {
  private imgBaseUrl = environment.baseUrl;
  private _origin = '';

  // Getter for origin
  get getOrigin(): string {
    return this._origin;
  }

  // Setter for origin
  set setOrigin(value: string) {
    this._origin = value;
  }

  constructor(private http: HttpClient) {}

  getImageUrl(origin: string): Observable<any> {
    const params = new HttpParams().set('origin', origin);

    return this.http.post<any>(this.imgBaseUrl+ 'Insurance/GetHomeImage/origin', {}, { params, responseType: 'json' })
    .pipe(
      map(response => response.value.urlImagenMediador) // Extrae la URL de la imagen desde JSON
    );
  }
}
