import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private sharedData: { [key: string]: string | string[] | number } = {};

  setSharedData(variableName: string, value: string | string[] | number) {
    this.sharedData[variableName] = value;
  }

  getSharedData(variableName: string) {
    return this.sharedData[variableName];
  }
}
