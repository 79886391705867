import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import { ContractInterface, RateInterface } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class InsuranceService {
  private readonly _rates = new BehaviorSubject<RateInterface>({
    HasPrivatePolicy: false,
    IsMediator: false,
    Id: 0,
    pricingOffers: [],
    PrivacityMessage: '',
  });
  private readonly _contract = new BehaviorSubject<ContractInterface>({
    postalCode: '',
    startDateInsurance: '',
    insureds: [
      {
        birthday: '',
      },
    ],
    origin: '',
  });

  get rates(): RateInterface {
    return this._rates.getValue();
  }

  set rates(val: RateInterface) {
    this._rates.next(val);
  }

  get contract(): ContractInterface {
    return this._contract.getValue();
  }

  set contract(val: ContractInterface) {
    this._contract.next(val);
  }
}
