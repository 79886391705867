import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContractService } from './home/services';
import { ConfigurationService } from './home/services/configuration.service';
import { HttpClientModule } from '@angular/common/http';
import { ImageService } from './home/services/image.service';
@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule],
  providers: [ContractService, ConfigurationService, ImageService],
  bootstrap: [AppComponent],
})
export class AppModule {}
