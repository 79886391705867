import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ContractInterface, customerInterface, RateBodyInterface } from '../interfaces';

@Injectable()
export class ContractService {
  url = environment.baseUrl;

  private _origin = '';

  get getOrigin(): string {
    return this._origin;
  }

  set setOrigin(value: string) {
    this._origin = value;
  }

  async getList(body: ContractInterface): Promise<RateBodyInterface> {
    const response = await fetch(`${this.url}Pricing`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error('Error service');
    } else {
      return response.json();
    }
  }

  async getHire(body: ContractInterface): Promise<{ value: { url: string } }> {
    const response = await fetch(`${this.url}Insurance/Hire`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    return response.json();
  }

  async sendMail(body: customerInterface): Promise<{ value: { url: string } }> {
    const response = await fetch(`${this.url}Contact`, {
      method: 'POST',
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error('Error service');
    } else {
      return response.json();
    }
  }
}
