/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Analytics, AnalyticsInstance } from 'analytics';

import { ContractService } from './contract.service';
import { AnalyticInterface } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private analytics = Analytics({ app: 'dkv-app' });
  private mediator: string;

  constructor(private contractService: ContractService, private router: Router, private route: ActivatedRoute) {
    this.analytics.on('*', async ({ payload }: any) => {
      if (payload.type === 'track' && payload.event === 'showPrice') {
        await this.sendMetrics(payload);
      }
    });
    this.route.queryParams.subscribe((params) => {
      if (params['mediator']) {
        this.mediator = `${params['mediator']}`;
      }
    });
  }

  getAnalytics(): AnalyticsInstance {
    return this.analytics;
  }

  async sendMetrics(metric: any): Promise<void> {
    const customBody: AnalyticInterface = {
      birthday: null,
      insuranceStage: metric.properties?.stage,
      numberOfPeople: metric.properties?.personCount?.toString(),
      postalCode: metric.properties?.postalCode,
      request: document.URL,
      user: metric.userId,
      mediator: this.mediator,
    };
    await fetch(`${environment.analyticsUrl}Analytics/UserMetrics`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(customBody),
    });
  }
}
