import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ConfigurationInterface} from '../interfaces';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ConfigurationService {
  url = environment.baseUrl;

  private _origin = '';

  get getOrigin(): string {
    return this._origin;
  }

  set setOrigin(value: string) {
    this._origin = value;
  }

  constructor(private http: HttpClient) {}

  getConfiguration(campo: string): Observable<ConfigurationInterface> {
    const params = new HttpParams().set('campo', campo);
    return this.http.get<ConfigurationInterface>(`${this.url}Configuration`, { params })
      .pipe(
        map((response: any) => response.value),
        catchError(error => {
          console.error('Error en el servicio de configuración', error);
          throw new Error('Error en el servicio de configuración');
        })
      );
  }

}
