import { Component, OnInit } from '@angular/core';

import { AnalyticsService } from './home/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(private analyticService: AnalyticsService) {}

  ngOnInit(): void {
    const userID = this.generateGuid();
    this.analyticService.getAnalytics().identify(userID, {
      pro: true,
    });
  }

  generateGuid = () => {
    const timestamp = Date.now().toString();
    const randomString = Math.random().toString().substring(2, 6);
    return `${timestamp}${randomString}`;
  };
}
